import Swiper, {Navigation, Pagination, Autoplay} from 'swiper'
Swiper.use([Navigation, Pagination, Autoplay]);

export default function sliders() {

  (function($) {
    $(window).on('load', function () {
           // get all sliders, we need to loop them due to different settings + nav
           var swipers = document.querySelectorAll('.testimonials-swiper');
           swipers.forEach(function(el,index){
            var closestSection = el.closest('section');
            var controls = closestSection.querySelector('.control');

                 // slider settings
                 var options = {
                  loop: false,
                  autoHeight: true,
                    //centeredSlides: true,
                   // autoplay: {
                        //delay: 1800,
                       // disableOnInteraction: true,
                    //},
                    pagination: {
                      el: ".swiper-pagination",
                      clickable: true,
                    },
                    freeMode: true,
                    slidesPerView: 1,
                    spaceBetween: 20,
                    on: {
                      slideChange: (swiper) => {
                        let image =
                        swiper.slides[
                        swiper.activeIndex
                        ].querySelector('img[data-src]')
                        if (image) {
                          loadSlideImage(image);
                        }
                      },
                    },

                    breakpoints: {
                      640: {
                        spaceBetween: 16,
                        slidesPerView: 2,
                      },
                      992: {
                        spaceBetween: 16,
                        slidesPerView: 3,
                      },
                      1172: {
                        spaceBetween: 16,
                        slidesPerView: 3,
                      },
                    },
                  };

                // init slider
                new Swiper(el, options);
              });
         })

  })( jQuery );

}


function loadSlideImage(image) {
  let src = image.getAttribute('[data-src]')
  let sourceSet = image.parentNode.querySelectorAll('[data-srcset]')
  if (sourceSet.length > 0) {
    for (let i = 0; i < sourceSet.length; i++) {
      sourceSet[i].srcset = sourceSet[i].getAttribute('data-srcset')
    }
  }
  if ('img' === image.tagName.toLowerCase()) {
    if (src) {
      image.src = src
    }
  } else {
    image.style.backgroundImage = "url('" + src + "')"
  }
}